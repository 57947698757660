* {
  -webkit-font-smoothing: antialiased;
  -moz-os-font-smoothing: grayscale;
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  padding: 0;
  margin: 0;
  height: 100%;
}
body {
  font-family: Quicksand;
  padding: 0;
  margin: 0;
  background: rgb(92,33,170);
  background: -webkit-linear-gradient(left, rgba(92,33,170,1) 0%, rgba(143,67,242,1) 55%, rgba(117,67,242,1) 100%);
  background: -o-linear-gradient(left, rgba(92,33,170,1) 0%, rgba(143,67,242,1) 55%, rgba(117,67,242,1) 100%);
  background: linear-gradient(to right, rgba(92,33,170,1) 0%, rgba(143,67,242,1) 55%, rgba(117,67,242,1) 100%);
  min-height: 100%;
}

.card-img-top {
  background-color: #333;
  padding: 18px;
}
.card-img-top-l {
  padding: 40px !important;
}

.card-body {
  padding: 1rem !important;
  overflow: auto;
}

.card-text-l {
  font-size: 13px;
  height: 250px;
  white-space: nowrap;
}

.card-body p {
  padding: 0px;
  margin-bottom: 0px;
}

.card {
  margin-bottom: 20px;
}

.a-nowrap {
  white-space: nowrap;
}

.pagination {
  margin-top: 20px;
}

.pc25{
  width: 23%;
  margin-right: 1%;
  margin-left: 1%;
}

.list-group {
  font-size: 13px;
}

.white {
  color: white;
}

.btn-back {
  height: 50px;
  line-height: 35px;
  width: 100%;
}

.list-size {
  text-align: center;
  font-size: 15px;
  cursor: pointer;
  color: #007bff;
}

.modal-dialog {
  max-width: 70% !important;
}

.span-price {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
}

.span-sol-price {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  text-align: right;
}

.less-bottom {
  margin-bottom: 0.7rem !important;
}

hr {
  margin-bottom: 0.25rem !important;
}

.buttons {
  text-align: right;
  margin-top: 4px;
}

.price-positive {
  color: #228422;
}

.price-negative {
  color: #9e4141;
}

.alert-bottom {
  margin-bottom: 0;
  margin-top: 8px;
}

.card {
  margin-bottom: 30px !important;
}

.alert-low-margin {
  margin-bottom: 10px;
}