* {
  -webkit-font-smoothing: antialiased;
  -moz-os-font-smoothing: grayscale;
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  padding: 0;
  margin: 0;
  height: 100%;
}
body {
  font-family: Quicksand;
  padding: 0;
  margin: 0;
  background: rgb(92,33,170);
  background: -webkit-linear-gradient(left, rgba(92,33,170,1) 0%, rgba(143,67,242,1) 55%, rgba(117,67,242,1) 100%);
  background: -o-linear-gradient(left, rgba(92,33,170,1) 0%, rgba(143,67,242,1) 55%, rgba(117,67,242,1) 100%);
  background: linear-gradient(to right, rgba(92,33,170,1) 0%, rgba(143,67,242,1) 55%, rgba(117,67,242,1) 100%);
  min-height: 100%;
}

.card-img-top {
  width: 40px;
  background-color: #333;
  padding: 18px;
}

.card-body {
  padding: 1rem !important;
  overflow: auto;
}

.card-text {
  font-size: 13px;
  height: 68px;
  white-space: nowrap;
}

.card-body p {
  padding: 0px;
  margin-bottom: 0px;
}

.card {
  margin-bottom: 20px;
}

.a-nowrap {
  white-space: nowrap;
}

.pagination {
  margin-top: 20px;
}

.pc15{
  width: 18%;
  margin-right: 1%;
  margin-left: 1%;
}

.list-group {
  font-size: 13px;
}