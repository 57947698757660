.wallet__provider-wrapper{
  border: 1px solid #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
}

.wallet__provider-button{
  margin: 0.5rem !important;
  color: #ffffff !important;
  border-color: #ffffff !important;
  display: flex !important;
  justify-content: space-between !important;
}

.wallet__provider-logo{
  height: 1rem;
  width: 1rem;
  background-size: contain;
  background-repeat: no-repeat;
  padding-right: 2rem;
  &--sollet-io, &--Sollet-Extension{
    background-image: url("./assets/wallets/sollet.png");
  }
  &--Phantom{
    background-image: url("./assets/wallets/phantom.png");
  }
}

.validated-btn {
  border: 2px solid #1faf58!important;
  color: #1faf58!important;
}